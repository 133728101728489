import React, { useRef } from "react";
import "./about.css";
import RenderWhenVisible from "../renderWhenVisible/RenderWhenVisible.component";

import ABOUT_IMAGE from "../../assets/about-image.webp";
import { Image } from "../Image/Image.component";

import { FaAward } from "react-icons/fa";

import { VscFolderLibrary } from "react-icons/vsc";
import { BsBook } from "react-icons/bs";
import {
  ABOUT_SECTION_TYPE,
  DESCRIPTION
} from "./About.config";

import { counterHelper } from "../../utils/counter/counterHelper";

const About = () => {
  const elementRef = useRef(),
    isVisible = RenderWhenVisible(elementRef),
    scandiweb_link = "https://scandiweb.com/",
    idea_link = "https://idea-commerce.com/",
    creativestyle_link = "https://www.creativestyle.pl/";

  const startDate = new Date("2022-10-13");

  return (
    <section id="about">
      <h5>Get to Know</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__me" ref={elementRef}>
          <div className="picture__placeholder__wrapper">
            {isVisible && (
              <Image
                src={ABOUT_IMAGE}
                alt={"About Me Image"}
                type={ABOUT_SECTION_TYPE}
              />
            )}
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <FaAward className="about__icon" />
              <h5>Experience</h5>
              <small>Over 3 years of commercial experience</small>
            </article>
            <article 
              className="about__card time__line" 
            >
              <BsBook className="about__icon" />
              <h5>Work activity</h5>
              <small>
                Worked for{" "}
                <a
                  className="company__link"
                  href={scandiweb_link}
                  target="_blank"
                  rel="nofollow"
                >
                  Scandiweb
                </a>{" "}
                ,{" "}
                <a
                  className="company__link"
                  href={idea_link}
                  target="_blank"
                  rel="nofollow"
                >
                  IDEA
                </a>{" "}
                and{" "}
                <a
                  className="company__link"
                  href={creativestyle_link}
                  target="_blank"
                  rel="nofollow"
                >
                  Creativestyle
                </a>
              </small>{" "}
            </article>
            <article className="about__card">
              <VscFolderLibrary className="about__icon" />
              <h5>Projects</h5>
              <a className="small__link" href="/#portfolio">
                Worked on over 25 commercial projects
              </a>
            </article>
          </div>
          <p>{DESCRIPTION}</p>
          <a href="/#contact" rel="follow" className="btn btn-primary">
            Let's Talk
          </a>
        </div>
      </div>
    </section>
  );
};
export default About;
