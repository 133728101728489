import { counterHelper } from "../../utils/counter/counterHelper";

const startDate = new Date("2022-10-13");

export const ABOUT_SECTION_TYPE = "about_section_type";
export const DESCRIPTION =
  "I'm a certified Full-Stack and Magento 2 developer with over 3 years of hands-on experience, contributing to over 25 projects and delivering two end-to-end solutions. My expertise spans front-end, back-end, and e-commerce development, supported by a diverse skill set in multiple languages. Committed to delivering high-quality code, I'm dedicated to optimizing performance and continuously evolving with new technologies in web development.";

export const WORK_EXPERIENCE = [
  {
    company_name: "Scandiweb",
    time_range: "09/2022 - 10/2023",
  },
  {
    company_name: "IDEA Commerce",
    time_range: "10/2023 - 01/2025",
  },
  {
    company_name: "Creativestyle",
    time_range: "01/2025 - until now"
  }
];
