import IMG11 from "../../assets/NCP.webp";
import IMG12 from "../../assets/MOON.webp";
import IMG13 from '../../assets/popup/projects-collage.webp';
import IMG14 from '../../assets/popup/yt-clone.webp';
import IMG15 from '../../assets/popup/react-ecommerce.webp';

export const image_data = [
  {
    id: 1,
    image: IMG11,
    title: "Magento PWA",
    alt: "Magento PWA",
    websiteSource: "https://www.northcoastpottery.com",
    target: "_blank",
    description: 'I spearheaded the end-to-end development of this e-commerce site. Leveraging a powerful tech stack, including <p class="tech-stack">Magento 2, PWA, PHP, MySQL, React.js, GraphQL, TypeScript, and Redux</p> I contributed to the project from its inception to the final deployment. My involvement spanned critical phases such as migration and the seamless transition to the live environment, showcasing my comprehensive role in the project\'s success.',
    cta: "Read More",
  },
  {
    id: 20,
    image: IMG12,
    title: "Magento with Hyva Theme",
    alt: "Magento with Hyva theme project",
    websiteSource: "https://moonclimbing.com",
    target: "_blank",
    cta: "Read More",
    description: 'I played a pivotal role in the comprehensive development lifecycle of this commerce platform. My technical expertise was showcased through the utilization of <p class="tech-stack">Magento 2, PHP, MySQL, Hyva Theme, JavaScript, Alpine.js, Knockout.js, and Tailwind CSS<p> From project initiation to the final implementation, I worked collaboratively with the team to ensure a seamless development process. My responsibilities encompassed tasks such as migration and the successful deployment of the platform, demonstrating my commitment to the project\'s success and the adaptability to diverse technological components.'
  },
  {
    id: 21,
    image: IMG13,
    title: "Other Magento Projects",
    alt: "Show all projects image",
    target: "_self",
    cta: "Read More",
    description: 'I\'ve also contributed to over 15 Magento 2 e-commerce projects, working on custom-themed websites using technologies like <p class="tech-stack">PHP, MySQL, JavaScript, and LESS</p> My role seamlessly covered both frontend and backend development, from regular maintenance to enhancing functionalities and implementing custom features. This experience has fortified my expertise, refining my ability to deliver and optimize robust e-commerce solutions.'
  },
  {
    id: 23,
    image: IMG14,
    title: "YouTube Clone",
    alt: "YouTube Clone Project",
    websiteSource: "https://github.com/wiktorkoscielny/YouTube",
    target: "_blank",
    description: 'This is my project made using technologies souch as <p class="tech-stack"> React, Typescript, Redux, and Tailwind<p> The data are fetched from YoutubeAPI, and the project have CI/CD pipeline set.'
  },
  {
    id: 24,
    image: IMG15,
    title: "React E-commerce",
    alt: "React ecommerce project",
    websiteSource: "https://github.com/wiktorkoscielny/react-ecommerce-graphql",
    target: "_blank",
    description: 'This is one of my projects, currently undergoing redevelopment, but quite interesting nonetheless. It\'s an e-commerce website created in React.js. The application has a hosted production environment. Data is fetched from an API endpoint hosted on a serverless lambda using GraphQL.'
  }
];

export const PORTFOLIO_TYPE = "portfolio_type";
